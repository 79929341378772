import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IImage, ILink, IMegaMenuSettings } from '@ncg/data';
import { FeatureDetectionService, HeaderService, NavigationService, ScrollStatusService, SettingsService, STATUS } from '@ncg/ui';
import { Subject, take } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'ncg-header',
    template: `
        <div class="header__backdrop" [ngClass]="{ 'header__backdrop--is-open': isMegaMenuOverlayVisible }"></div>
        <div class="header__wrapper" [style.height]="headerHeight + 'px'">
            <header
                #header
                class="header"
                [ngClass]="{ 'header--is-sticky': isFixed }"
                [style.transform]="hideNavBar ? 'translate3d(0, ' + -headerHeight + 'px, 0)' : ''"
            >
                <div class="header__container">
                    <div class="columns is-vcentered is-gapless is-mobile">
                        <!-- Desktop Menu -->
                        <ncg-main-menu
                            (isMegaMenuVisibleSettings)="onMegaMenuChange($event)"
                            class="header__main-menu column is-5-desktop is-4-touch is-hidden-touch"
                        ></ncg-main-menu>

                        <div class="header__logo column is-2-desktop is-4-touch">
                            <a class="logo" [routerLink]="[logoLink?.url || '/']">
                                <img
                                    *ngIf="logo"
                                    loading="eager"
                                    [src]="logo.url"
                                    [alt]="logo.altText"
                                    [attr.width]="logo.width || null"
                                    [attr.height]="logo.height || null"
                                />
                            </a>
                        </div>

                        <div class="column is-5-desktop is-4-touch">
                            <ncg-meta-menu class="header__meta-menu"></ncg-meta-menu>
                        </div>

                        <!-- Mobile Menu -->
                        <div class="header__mobile-menu column is-5-desktop is-4-touch is-hidden-desktop">
                            <button
                                ncgMobileMenuTrigger
                                [hasMobileHeader]="false"
                                (status)="onMobileStatusChange($event)"
                                type="button"
                                class="button is-clean"
                                aria-label="menu"
                                aria-expanded="false"
                            >
                                <ng-container *ngIf="isMobileMenuOpen">
                                    <svg-icon-sprite
                                        [src]="'close_light'"
                                        [viewBox]="'0 0 30 30'"
                                        [width]="'30px'"
                                        [height]="'30px'"
                                        aria-hidden="true"
                                        classes=""
                                    ></svg-icon-sprite>
                                    <div class="mobile-menu__title mobile-menu__close--text">{{ 'header.close' | translate }}</div>
                                </ng-container>

                                <ng-container *ngIf="!isMobileMenuOpen">
                                    <svg-icon-sprite
                                        [src]="'nav'"
                                        [viewBox]="'0 0 30 30'"
                                        [width]="'30px'"
                                        [height]="'30px'"
                                        aria-hidden="true"
                                        class="mobile-menu__nav-icon"
                                        classes=""
                                    ></svg-icon-sprite>
                                    <div class="mobile-menu__title mobile-menu__close--text">{{ 'header.menu' | translate }}</div>
                                </ng-container>
                            </button>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('header') private readonly headerElement: ElementRef;
    isMobileMenuOpen = false;
    isMegaMenuOverlayVisible = false;
    isFixed: boolean;
    previousPosition: number;
    hideNavBar = false;
    headerHeight = 115;
    currentPosition: number;
    languageLinks?: Record<string, string>;
    logo?: IImage;
    logoLink?: ILink;
    private readonly unsubscribe = new Subject<void>();

    constructor(
        private readonly cd: ChangeDetectorRef,
        private readonly headerService: HeaderService,
        private readonly scrollStatusService: ScrollStatusService,
        private readonly featureDetection: FeatureDetectionService,
        private readonly navigationService: NavigationService,
        private readonly settingsService: SettingsService,
        private readonly router: Router
    ) {}

    ngOnInit() {
        this.navigationService.languageLinks$.pipe(takeUntil(this.unsubscribe)).subscribe((data) => {
            this.languageLinks = data;
            this.cd.markForCheck();
        });

        this.scrollStatusService
            .getScrollPosition()
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((position: number) => {
                this.currentPosition = position;
                this.handleHeaderPosition();
            });

        this.settingsService
            .get()
            .pipe(take(1), takeUntil(this.unsubscribe))
            .subscribe((settings) => {
                this.logoLink = settings.logoLink;
                this.logo = settings.logoImage;
            });
    }

    isActive(url: string): boolean {
        return this.router.isActive(url, true);
    }

    ngAfterViewInit(): void {
        this.setInitialValues();
    }

    ngOnDestroy() {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    setInitialValues() {
        if (this.featureDetection.isBrowser() && this.headerElement) {
            this.headerHeight = this.headerElement.nativeElement.offsetHeight;
            this.previousPosition = this.headerHeight;
            this.headerService.updateModelPageHeader(this.headerHeight);
            this.cd.markForCheck();
        }
    }

    handleHeaderPosition() {
        // Alway set a fixed header, when mobile nav is open.
        if (this.isMobileMenuOpen) {
            this.isFixed = true;
            this.hideNavBar = false;
            this.cd.markForCheck();
            return;
        }

        if (this.currentPosition > this.headerHeight) {
            if (this.currentPosition > this.previousPosition) {
                this.hideNavBar = true;
                this.previousPosition = this.currentPosition - 1;
            } else {
                this.isFixed = true;
                this.hideNavBar = false;
                this.previousPosition = this.currentPosition;
            }
        } else if (this.currentPosition === 0) {
            this.isFixed = false;
            this.hideNavBar = false;
            this.previousPosition = this.headerHeight;
        }

        this.cd.markForCheck();
    }

    onMobileStatusChange(status: STATUS) {
        this.isMobileMenuOpen = status === 0;
        if (this.isMobileMenuOpen) {
            this.handleHeaderPosition();
        }
    }

    onMegaMenuChange(settings: IMegaMenuSettings) {
        this.isMegaMenuOverlayVisible = settings.isVisible;
        this.cd.detectChanges();
    }
}
